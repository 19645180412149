import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { colors } from "../../app/theme/colors";

export const useUserManagementPageStyles = makeStyles()((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
  form: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(2),
    border: "1px solid #ccc",
    borderRadius: theme.spacing(1),
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "100%",
    },
    "& .MuiButton-root": {
      margin: theme.spacing(1),
    },
  },
  title: {
    margin: theme.spacing(4),
    textAlign: "center",
    textTransform: "uppercase",
    fontWeight: "bold",
    fontSize: "1.5rem",
    lineHeight: "2rem",
    letterSpacing: "0.1em",
    color: "#707070",
  },
  checkboxLabel: {
    textAlign: "start",
    "& .MuiFormControlLabel-label": {
      color: colors.inputLabel,
    },
    "& .MuiTypography-root.MuiTypography-body1.MuiFormControlLabel-label": {
      fontSize: "0.9rem",
    },
  },
  checkbox: {
    color: colors.primary,
    "&.Mui-checked": {
      color: colors.primary,
    },
  },
  inputTitle: { color: colors.inputLabel, textAlign: "start" },
  boxWrapper: {
    backgroundColor: "#f9f9f9",
    padding: "2rem",
    borderRadius: "10px",
    width: "100%",
    height: "100%",
  },
  autocompleteTextField: {
    "& .MuiInputBase-input": {
      marginTop: "-0.2rem",
    },

    "& .MuiInputBase-root": {
      borderRadius: "5px",
      height: "2rem",
    },
  },
  autocomplete: {
    "& .MuiOutlinedInput-root.Mui-focused": {
      "& > fieldset": {
        borderColor: colors.sectionTitle,
      },
    },
  },
}));

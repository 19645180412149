import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Account } from "../models/account/account";
import LoginRequest from "../models/auth/requests/loginRequest";
import jwtDecode from "jwt-decode";
import { RootState } from "../app/redux/store";
import { Token } from "../models/enums/token";
import { Tokens } from "../models/users/tokens";
import { RefreshTokenRequest } from "../models/auth/requests/refreshTokenRequest";
import { Auth } from "../app/redux/adminConsoleApi";
import { ApiStatus } from "../models/app/apiStatus";
import { ApiError } from "../models/app/apiError";

interface authState {
  account: Account | null;
  isFirstLogin: boolean | null;
  loginFailed: boolean | null;
  forgottenPassword: boolean | null;
  accessToken: string | null;
  refreshToken: string | null;
  isActive: boolean | null;
  statuses: {
    loginStatus: ApiStatus;
  };
  exceptions: {
    loginException: ApiError | null;
    getUserByEmailException: ApiError | null;
  };
}
let initialState: authState = {
  account: null,
  isFirstLogin: null,
  loginFailed: null,
  forgottenPassword: null,
  accessToken: "",
  refreshToken: "",
  isActive: null,
  statuses: {
    loginStatus: ApiStatus.Idle,
  },
  exceptions: {
    loginException: null,
    getUserByEmailException: null,
  },
};

export const selectLoginStatus = (state: RootState) =>
  state.auth.statuses.loginStatus;

export const selectAccount = (state: RootState) => state.auth.account;

export const selectLoginFailed = (state: RootState) => state.auth.loginFailed;

export const login = createAsyncThunk(
  "auth/login",
  async (loginRequest: LoginRequest) => {
    const response = await Auth.Login(loginRequest);
    return response.data;
  }
);

export const refresh = createAsyncThunk(
  "auth/refresh",
  async (loginRequest: RefreshTokenRequest) => {
    const response = await Auth.Refresh(loginRequest);
    return response.data;
  }
);

const onSetTokens = (
  state: authState,
  action: { payload: any; type?: string }
) => {
  const { accessToken, refreshToken } = action.payload as Tokens;

  state.accessToken = accessToken;

  localStorage.setItem(Token.AccessToken, accessToken);
  localStorage.setItem(Token.RefreshToken, refreshToken);
};

const onLogout = (state: authState) => {
  localStorage.removeItem(Token.AccessToken);
  localStorage.removeItem(Token.RefreshToken);

  state.accessToken = null;

  state.account = null;

  state.statuses.loginStatus = ApiStatus.Idle;
};

const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    logout: (state) => {
      onLogout(state);
    },
    setTokens: (state, action) => {
      onSetTokens(state, action);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state, {}) => {
        state.loginFailed = false;
        state.statuses.loginStatus = ApiStatus.Pending;
      })
      .addCase(login.fulfilled, (state, { payload }) => {
        if (!payload) {
          return;
        }

        var accessToken = payload.accessToken;
        var refreshToken = payload.refreshToken;
        localStorage.setItem("accessToken", accessToken);
        state.accessToken = accessToken;
        state.refreshToken = refreshToken;
        var response = payload;
        state.statuses.loginStatus = ApiStatus.Fulfilled;
        if (response.isFirstLogin) {
          state.isFirstLogin = true;
          state.loginFailed = true;
          var jwt = response.accessToken;
          var claims: any = jwtDecode(jwt);
          state.account = {
            id: claims.userId,
            firstName: claims.given_name,
            lastName: claims.family_name,
            email: claims.email,
            isActive: true,
            isDeleted: false,
            phone: "",
            roleName: "",
          };
        } else {
          if (response.accessToken !== null) {
            var jwt = response.accessToken;
            var claims: any = jwtDecode(jwt);
            state.account = {
              id: claims.userId,
              firstName: claims.given_name,
              lastName: claims.family_name,
              email: claims.email,
              isActive: true,
              isDeleted: false,
              phone: "",
              roleName: "",
            };
            state.loginFailed = null;
          }
        }

        state.statuses.loginStatus = ApiStatus.Fulfilled;
      })
      .addCase(login.rejected, (state, {}) => {
        state.loginFailed = true;
        state.statuses.loginStatus = ApiStatus.Rejected;
      })
      .addCase(refresh.fulfilled, (state, action) => {
        onSetTokens(state, action);
        state.exceptions.loginException = null;
      });
  },
});
export const { logout, setTokens } = authSlice.actions;

export default authSlice.reducer;

import CleaningServicesOutlinedIcon from "@mui/icons-material/CleaningServicesOutlined";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import SettingsBackupRestoreOutlinedIcon from "@mui/icons-material/SettingsBackupRestoreOutlined";
import UploadOutlinedIcon from "@mui/icons-material/UploadOutlined";
import {
  Autocomplete,
  Box,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/redux/hooks";
import { colors } from "../../app/theme/colors";
import AppButton from "../../components/appButton/appButton";
import BasicInput from "../../components/basicInput/basicInput";
import {
  EmptyGuid,
  InitAccount,
  InitAssociationDto,
} from "../../constants/constants";
import { Account } from "../../models/account/account";
import { AssociationDto } from "../../models/associations/associationDto";
import { Client } from "../../models/associations/client";
import { ColorModel, ColorValues } from "../../models/associations/colorDto";
import { DatabaseDto } from "../../models/associations/databaseDto";
import SnackbarModel from "../../models/snackbarModel";
import { getAllClients, selectClients } from "../../slices/accountSlice";
import { setSnackbar } from "../../slices/snackbarSlice";
import { useOrganisationManagementPageStyles } from "./organisationManagementStyles";
import { getAllModules, selectModules } from "../../slices/moduleSlice";
import { Module } from "../../models/associations/module";
import DatabaseModel from "../../models/database/databaseModel";
import OwnerModel from "../../models/associations/ownerModel";
import {
  getAllAssociations,
  getAssociationById,
  resetSavedAssociationStatus,
  resetSelectedAssociation,
  saveOrganisation,
  selectAssociationById,
  selectAssociations,
  selectStatusSaveAssociation,
} from "../../slices/associationSlice";
import AssociationShort from "../../models/associations/associationShort";
import { toast } from "react-toastify";

export const OrganisationManagement = () => {
  const dispatch = useAppDispatch();
  const { classes } = useOrganisationManagementPageStyles();
  const associationById = useAppSelector(selectAssociationById);
  const associations = useAppSelector(selectAssociations);
  const savedOrganisationStatus = useAppSelector(selectStatusSaveAssociation);
  const [account, setAccount] = useState<Account>(InitAccount);
  const [association, setAssociation] =
    useState<AssociationDto>(InitAssociationDto);

  const colorValues: ColorValues = {
    primary: association.association.color.primary,
    secondary: association.association.color.secondary,
    success: association.association.color.success,
    error: association.association.color.error,
  };

  const modules = useAppSelector(selectModules);
  const [selectedModules, setSelectedModules] = useState<Module[]>([]);
  const [selectedAssociation, setSelectedAssociation] =
    useState<AssociationShort | null>(null);
  const [isMembershipModuleSelected, setIsMembershipModuleSelected] =
    useState(false);
  const [operationType, setOperationType] = useState<string>("");
  const onChangeCheckbox = (selectedModule: Module, event: any) => {
    if (event.target.checked) {
      if (selectedModule.databaseType) {
        const newDatabaseOptions: DatabaseModel = {
          id: EmptyGuid,
          associationId: EmptyGuid,
          databaseName: "",
          serverName: "",
          username: "",
          password: "",
          label: selectedModule.name,
          databaseTypeId: selectedModule.databaseType?.id || null,
        };
        const newAssosiation: AssociationDto = { ...association };
        const newDatabases: DatabaseModel[] = [
          ...newAssosiation.association.databases,
        ];
        const newModuleIds: string[] = [
          ...newAssosiation.association.moduleIds,
        ];
        newModuleIds.push(selectedModule.id);
        newAssosiation.association.moduleIds = newModuleIds;
        newDatabases.push(newDatabaseOptions);
        newAssosiation.association.databases = newDatabases;

        setAssociation(newAssosiation);
      }
    } else {
      if (selectedModule.databaseType) {
        const newAssosiation: AssociationDto = { ...association };
        const existDatabases: DatabaseModel[] = [
          ...newAssosiation.association.databases,
        ];
        const existModuleIds: string[] = [
          ...newAssosiation.association.moduleIds,
        ];
        const newModuleIds = existModuleIds.filter(
          (x) => x !== selectedModule.id
        );
        const newDatabases = existDatabases.filter(
          (x) => x.databaseTypeId !== selectedModule.databaseType?.id
        );
        newAssosiation.association.databases = newDatabases;
        newAssosiation.association.moduleIds = newModuleIds;

        setAssociation(newAssosiation);
      }
    }
  };

  const handleLogoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      if (file.size <= 2000000) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          setAssociation((prevState) => ({
            ...prevState,
            association: {
              ...prevState.association,
              logo: reader.result as string,
            },
          }));
        };
      } else {
        const snackbar: SnackbarModel = {
          message:
            "File upload failed. Please make sure the image you're uploading is no larger than 2MB in size..",
          severity: "error",
          show: true,
        };
        dispatch(setSnackbar(snackbar));
      }
    }
  };

  function handleInputChange(e: any, field: string, index: number) {
    setAssociation((prevState) => {
      const updatedAssociation = { ...prevState.association };
      const updatedDatabase = [...updatedAssociation.databases];
      updatedDatabase[index] = {
        ...updatedDatabase[index],
        [field]: e.target.value,
      };
      updatedAssociation.databases = updatedDatabase;
      return { ...prevState, association: updatedAssociation };
    });
  }

  const onChangeAccount = (value: string, name: keyof OwnerModel) => {
    setAssociation((prevState) => ({
      ...prevState,
      association: {
        ...prevState.association,
        owner: {
          ...prevState.association.owner,
          [name]: value,
        },
      },
    }));
  };

  const onChangeColor = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setAssociation((prevState) => ({
      ...prevState,
      association: {
        ...prevState.association,
        color: {
          ...prevState.association.color,
          [name]: value,
        },
      },
    }));
  };

  const onChangeAssociation = (value: string | number, name: string) => {
    setAssociation((prevState) => ({
      ...prevState,
      association: {
        ...prevState.association,
        [name]: value,
      },
    }));
  };

  const onResetColors = (name?: keyof ColorModel) => {
    if (name) {
      setAssociation((prevState) => ({
        ...prevState,
        color: { ...prevState.association.color, [name]: "" },
      }));
    } else {
      setAssociation((prevState) => ({
        ...prevState,
        color: { ...InitAssociationDto.association.color },
      }));
    }
  };
  const handleSaveOrganisation = () => {
    dispatch(saveOrganisation(association));
  };

  const handleAssociationChange = (
    event: React.ChangeEvent<{}>,
    value: AssociationShort | null
  ) => {
    setSelectedAssociation(value);
    if (value) {
      dispatch(getAssociationById(value?.id));
      dispatch(resetSelectedAssociation());
    } else {
      setAssociation(InitAssociationDto);
      setSelectedModules([]);
    }
  };

  const uploadLogoButtonId = "save-association-upload-logo-button";

  const displayDefaultAmount =
    association.association.defaultAmount === 0
      ? ""
      : association.association.defaultAmount;

  useEffect(() => {
    dispatch(getAllModules());
    dispatch(getAllAssociations());
  }, []);

  useEffect(() => {
    if (associationById) {
      setAssociation(associationById);
      const selectedModuleIds = associationById.association.moduleIds;
      const databases: DatabaseModel[] = [];
      associationById.association.databases.forEach((database) => {
        const databaseType = modules?.find(
          (x) => x.databaseType?.id === database.databaseTypeId
        );
        databases.push({ ...database, label: databaseType?.name || null });
      });
      setAssociation((prevState: AssociationDto) => ({
        ...prevState,
        association: {
          ...prevState.association,
          databases: databases,
        },
      }));
    }
  }, [associationById]);

  useEffect(() => {
    const isMembershipSelected = selectedModules.some(
      (module) => module.name === "Membership"
    );
    setIsMembershipModuleSelected(isMembershipSelected);
  }, [selectedModules]);

  useEffect(() => {
    if (savedOrganisationStatus === "Fulfilled") {
      if (operationType === "Update") {
        toast.success("Organisation is updated!", {
          position: "bottom-left",
          autoClose: 3000,
          hideProgressBar: true,
          onClose: () => {
            dispatch(resetSavedAssociationStatus());
          },
        });
      } else {
        toast.success("Organisation is created!", {
          position: "bottom-left",
          autoClose: 3000,
          hideProgressBar: true,
          onClose: () => {
            dispatch(resetSavedAssociationStatus());
          },
        });
        setAssociation(InitAssociationDto);
        dispatch(getAllAssociations());
      }
    }
  }, [savedOrganisationStatus]);

  const setIsModuleChecked = (moduleId: string): boolean => {
    const shouldBeChecked = association.association.moduleIds.find(
      (x) => x === moduleId
    );
    if (shouldBeChecked) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Grid container spacing={1} mt={2}>
      <Grid item md={4} xs={12} mb={1}>
        <Typography variant="body1" className={classes.inputTitle}>
          Select organisation
        </Typography>
        <Autocomplete
          id={"organisation-management-autocomplete-associations"}
          options={associations ? associations : []}
          getOptionLabel={(option) => option.name}
          value={selectedAssociation}
          onChange={handleAssociationChange}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              autoComplete="off"
              fullWidth
              className={classes.autocompleteTextField}
              size="small"
            />
          )}
        />
      </Grid>
      <Grid item md={12} xs={12}>
        {/* Owner */}
        <Box
          sx={{
            backgroundColor: "#f9f9f9",
            padding: "2rem",
            borderRadius: "10px",
            width: "100%",
          }}
        >
          <Grid item md={12} xs={12} mb={3}>
            <Typography variant="h6">{"Owner"}</Typography>
          </Grid>
          <Grid container spacing={2}>
            <Grid item md={3} xs={12}>
              <BasicInput
                id={"organisation-management-input-first-name"}
                required={true}
                label="First name"
                value={association.association.owner.firstName}
                onChange={(e) => {
                  onChangeAccount(e.target.value, "firstName");
                }}
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <BasicInput
                id={"organisation-management-input-last-name"}
                required={true}
                label="Last name"
                value={association.association.owner.lastName}
                onChange={(e) => {
                  onChangeAccount(e.target.value, "lastName");
                }}
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <BasicInput
                id={"organisation-management-input-email"}
                required={true}
                label="Email"
                value={association.association.owner.email}
                onChange={(e) => {
                  onChangeAccount(e.target.value, "email");
                }}
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <BasicInput
                id={"organisation-management-input-phone"}
                label="Phone"
                value={association.association.owner.phone}
                onChange={(e) => {
                  onChangeAccount(e.target.value, "phone");
                }}
              />
            </Grid>
          </Grid>

          <Grid container justifyContent={"space-beetween"}>
            <Grid
              item
              md
              sm={12}
              xs={12}
              display={"flex"}
              alignItems={"center"}
            >
              <FormGroup>
                <FormControlLabel
                  className={classes.checkboxLabel}
                  control={
                    <Checkbox
                      id={"organisation-management-checkbox-isMidenas"}
                      size="small"
                      className={classes.checkbox}
                      checked={account.isMidenas === true}
                      onChange={(e) => {
                        setAccount((prevState) => ({
                          ...prevState,
                          isMidenas: e.target.checked,
                        }));
                      }}
                    />
                  }
                  label="Midenas Role"
                />
              </FormGroup>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      {/* Association */}
      <Grid item md={6} xs={12}>
        <Box
          sx={{
            backgroundColor: "#f9f9f9",
            padding: "2rem",
            borderRadius: "10px",
            width: "100%",
            height: "100%",
          }}
        >
          <Grid item md={12} xs={12} mb={3}>
            <Typography variant="h6">{"Organisation preference"}</Typography>
          </Grid>
          <Grid container spacing={2}>
            <Grid item md={6} xs={12}>
              <BasicInput
                id={"organisation-management-input-name"}
                required={true}
                label="Organization name"
                value={association.association.name}
                onChange={(e) => onChangeAssociation(e.target.value, "name")}
                // disabled={associationSectionDisabled}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <BasicInput
                id={"organisation-management-input-email"}
                required={true}
                label="Organization email"
                value={association.association.email}
                onChange={(e) => onChangeAssociation(e.target.value, "email")}
                // disabled={associationSectionDisabled}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} mt={1}>
            <Grid item md={6}>
              <input
                accept=".png,.jpeg,.jpg"
                type="file"
                id={uploadLogoButtonId}
                onChange={handleLogoChange}
                style={{ display: "none" }}
              />
              <Grid style={{ display: "flex", flexDirection: "column" }}>
                {association.association.logo ? (
                  <div
                    style={{
                      marginBottom: "8px",
                    }}
                  >
                    <img
                      src={association.association.logo}
                      alt="Logo Preview"
                      style={{
                        maxWidth: "100%",
                        maxHeight: "150px",
                        objectFit: "contain",
                      }}
                    />
                  </div>
                ) : null}
              </Grid>

              {association.association.logo ? (
                <div
                  style={{
                    zIndex: 1,
                  }}
                >
                  <AppButton
                    id={"organisation-management-button-logo"}
                    onClick={() =>
                      document.getElementById(uploadLogoButtonId)?.click()
                    }
                    color={colors.primary}
                    hover={colors.primary}
                    label="Change Logo"
                    startIcon={<UploadOutlinedIcon />}
                  />
                </div>
              ) : (
                <AppButton
                  id={"organisation-management-button-upload-logo"}
                  component="span"
                  startIcon={<UploadOutlinedIcon />}
                  color={colors.primary}
                  hover={colors.primary}
                  label="Upload logo"
                  // disabled={associationSectionDisabled}
                  onClick={() =>
                    document.getElementById(uploadLogoButtonId)?.click()
                  }
                />
              )}
            </Grid>
            <Grid item md={6}>
              <Grid
                item
                md={12}
                textAlign="right"
                style={{ height: "36px" }}
                mb={1}
              >
                {!(
                  (association.association.color.primary === "" &&
                    association.association.color.secondary === "") ||
                  association.association.color.success === "" ||
                  association.association.color.error === ""
                ) && (
                  <AppButton
                    id={"organisation-management-button-colors"}
                    onClick={() => onResetColors()}
                    hover={colors.primary}
                    startIcon={
                      <SettingsBackupRestoreOutlinedIcon
                        style={{ marginLeft: "5px", cursor: "pointer" }}
                      />
                    }
                    label="Default colors"
                    color={colors.primary}
                  />
                )}
              </Grid>
              <Grid container item md={12} style={{ height: "140px" }}>
                {Object.keys(colorValues).map((c: string, i) => {
                  const colorPropName = c as keyof ColorValues;

                  if (!colorPropName) {
                    return <></>;
                  }

                  return (
                    <Grid
                      item
                      md={12}
                      xs={12}
                      key={c}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "10px",
                      }}
                    >
                      <Grid item md={4} xs={12}>
                        <Typography>{c}</Typography>
                      </Grid>
                      <Grid item md={3} xs={12}>
                        <label
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginRight: "10px",
                          }}
                        >
                          <input
                            type="color"
                            name={c}
                            defaultValue={
                              association.association.color[colorPropName] ===
                              ""
                                ? ""
                                : association.association.color[colorPropName]
                            }
                            onChange={onChangeColor}
                            style={{ marginLeft: "10px" }}
                            value={
                              i === 0
                                ? association.association.color.primary
                                : i === 1
                                ? association.association.color.secondary
                                : i === 2
                                ? association.association.color.success
                                : association.association.color.error
                            }
                            // disabled={associationSectionDisabled}
                          />
                        </label>
                      </Grid>
                      <Grid item md={3} xs={12}>
                        {association.association.color[colorPropName] !==
                          "" && (
                          <p
                            style={{
                              marginLeft: "5px",
                              fontSize: "14px",
                              color:
                                association.association.color[colorPropName],
                              margin: "0",
                            }}
                          >
                            {association.association.color[colorPropName]}
                          </p>
                        )}
                      </Grid>
                      <Grid item md={2}>
                        {association.association.color[colorPropName] && (
                          <SettingsBackupRestoreOutlinedIcon
                            onClick={() => onResetColors(colorPropName)}
                            style={{ marginLeft: "5px", cursor: "pointer" }}
                          />
                        )}
                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item md={6} xs={12}>
        <Grid item md={12} xs={12} mb={1}>
          <Box
            sx={{
              backgroundColor: "#f9f9f9",
              padding: "2rem",
              borderRadius: "10px",
              width: "100%",
              height: "100%",
            }}
          >
            <Grid item md={12} xs={12} mb={3}>
              <Typography variant="h6">{"Modules"}</Typography>
            </Grid>
            <Grid container mt={1}>
              <FormGroup>
                {modules?.map((module, i) => (
                  <FormControlLabel
                    key={i}
                    className={classes.checkboxLabel}
                    control={
                      <Checkbox
                        size="small"
                        className={classes.checkbox}
                        onChange={(e) => onChangeCheckbox(module, e)}
                        checked={setIsModuleChecked(module.id)}
                      />
                    }
                    label={module.name}
                    // disabled={associationSectionDisabled}
                  />
                ))}
              </FormGroup>
            </Grid>
          </Box>
        </Grid>
        <Grid item md={12} xs={12}>
          {association.association.databases.map((module, index) => (
            <Box
              mb={1}
              sx={{
                backgroundColor: "#f9f9f9",
                padding: "2rem",
                borderRadius: "10px",
                width: "100%",
                height: "100%",
              }}
            >
              <Grid item md={12}>
                <Typography variant="h6">
                  {module.label} {"Database"}
                </Typography>
              </Grid>
              <Grid container mt={1}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <BasicInput
                      required={true}
                      label="Server name"
                      value={
                        association.association.databases[index]?.serverName ||
                        ""
                      }
                      onChange={(e: any) =>
                        handleInputChange(e, "serverName", index)
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <BasicInput
                      required={true}
                      label="Database"
                      value={module.databaseName || ""}
                      onChange={(e: any) =>
                        handleInputChange(e, "databaseName", index)
                      }
                      // disabled={associationSectionDisabled}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <BasicInput
                      required={true}
                      label="Username"
                      value={module.username || ""}
                      onChange={(e: any) =>
                        handleInputChange(e, "username", index)
                      }
                      // disabled={associationSectionDisabled}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <BasicInput
                      required={true}
                      label="Password"
                      value={module.password || ""}
                      onChange={(e: any) =>
                        handleInputChange(e, "password", index)
                      }
                      // disabled={associationSectionDisabled}
                    />
                  </Grid>
                </Grid>
              </Grid>
              {module.label === "Membership" && (
                <Grid item md={12} xs={12}>
                  <Box
                    sx={{
                      backgroundColor: "#f9f9f9",
                      borderRadius: "10px",
                      width: "100%",
                    }}
                  >
                    <Grid item md={12} mt={3}>
                      <Typography variant="h6">{"Membership Fee"}</Typography>
                    </Grid>
                    <Grid container mt={1}>
                      <Grid container mb={2}>
                        <FormControlLabel
                          className={classes.checkboxLabel}
                          control={
                            <Checkbox
                              size="small"
                              className={classes.checkbox}
                              onChange={(e) => {
                                setAssociation((prevState) => ({
                                  ...prevState,
                                  association: {
                                    ...prevState.association,
                                    membershipFee: e.target.checked,
                                  },
                                }));
                              }}
                              checked={association.association.membershipFee}
                            />
                          }
                          label={"Membership Fee"}
                          // disabled={associationSectionDisabled}
                        />
                      </Grid>
                      <Grid container>
                        <Grid item md={12} sm={12} xs={12}>
                          <BasicInput
                            label="Default amount (monthly)"
                            type="number"
                            value={displayDefaultAmount}
                            onChange={(e) => {
                              if (!isNaN(+e.target.value)) {
                                onChangeAssociation(
                                  +e.target.value,
                                  "defaultAmount"
                                );
                              }
                            }}
                            disabled={!association.association.membershipFee}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              )}
            </Box>
          ))}
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        justifyContent="flex-end"
        mt={2}
        sx={{ gap: "1rem" }}
        mb={5}
      >
        <AppButton
          color={colors.primary}
          hover={colors.primary}
          label="Clear"
          startIcon={<CleaningServicesOutlinedIcon />}
          // onClick={onClearAssociation}
        />
        <AppButton
          label={association.association.id === EmptyGuid ? "Create" : "Update"}
          color={colors.green}
          hover={colors.green}
          disabled={savedOrganisationStatus === "Pending"}
          startIcon={
            savedOrganisationStatus === "Pending" ? (
              <CircularProgress
                className={classes.circularProgress}
                size={14}
              />
            ) : (
              <SaveOutlinedIcon />
            )
          }
          onClick={() => {
            const buttonLabel =
              association.association.id === EmptyGuid ? "Create" : "Update";
            setOperationType(buttonLabel); // Postavite operationType na vrednost labela
            handleSaveOrganisation(); // Pozovite funkciju za čuvanje organizacije
          }}
        />
      </Grid>
    </Grid>
  );
};

import { Account } from "../../models/account/account";
import ActiveAccountResponse from "../../models/account/activateAccountResponse";
import DeleteAccountRequest from "../../models/account/deleteAccountRequest";
import RoleModel from "../../models/account/roleModel";
import { ApiResponse } from "../../models/app/apiResponse";
import { AssociationDto } from "../../models/associations/associationDto";
import AssociationShort from "../../models/associations/associationShort";
import { Client } from "../../models/associations/client";
import { Module } from "../../models/associations/module";
import { LoginResponse } from "../../models/auth/responses/loginResponse";
import api from "./api";

export const Auth = {
  Login: async (loginRequest: any) =>
    api.post("/auth/login", loginRequest) as Promise<
      ApiResponse<LoginResponse>
    >,

  Refresh: async (request: any) =>
    api.post("/auth/refresh-token", request) as Promise<
      ApiResponse<LoginResponse>
    >,
};

export const Accounts = {
  Save: async (request: Account) =>
    api.put("/accounts/save", request) as Promise<ApiResponse<Account>>,

  GetAll: async () => api.get("/accounts") as Promise<ApiResponse<Account[]>>,

  GetAllClients: async () =>
    api.get("/accounts/get-all-clients") as Promise<ApiResponse<Client[]>>,
};

export const Modules = {
  GetAll: async () =>
    api.get("/association/get-all-modules") as Promise<ApiResponse<Module[]>>,
};

// export const Associations = {
//   Save: async (request: AssociationDto) =>
//     api.put("/association/save", request) as Promise<
//       ApiResponse<AssociationDto>
//     >,
// };

export const saveOrganisation: any = async (request: any) => {
  return api.post("/association/save", request) as Promise<
    ApiResponse<AssociationDto | null>
  >;
};

export const saveAccount: any = async (request: any) => {
  return api.post("/accounts/save", request) as Promise<
    ApiResponse<Account | null>
  >;
};

export const getAssociations: any = async () => {
  return api.get("/association/get-all") as Promise<
    ApiResponse<AssociationShort | null>
  >;
};

export const getAssociationById: any = async (id: string) => {
  return api.get("/association/" + id) as Promise<
    ApiResponse<AssociationDto | null>
  >;
};

export const getAccountsByAssociation: any = async (id: string) => {
  return api.get("/accounts/by-association/" + id) as Promise<
    ApiResponse<Account | null>
  >;
};

export const setAccountDelete: any = async (request: DeleteAccountRequest) => {
  return api.post("/accounts/set-delete", request) as Promise<
    ApiResponse<string>
  >;
};

export const activateAccount: any = async (id: any) => {
  return api.put("/accounts/set-account-active-status/" + id) as Promise<
    ApiResponse<ActiveAccountResponse>
  >;
};

export const getRoles: any = async () => {
  return api.get("/accounts/get-all-roles") as Promise<
    ApiResponse<RoleModel | null>
  >;
};

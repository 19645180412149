import { useEffect, useState } from "react";
import {
  CircularProgress,
  Container,
  FormLabel,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import BasicInput from "../../components/basicInput/basicInput";
import { useLoginPageStyles } from "./loginPageStyles";
import { colors } from "../../app/theme/colors";
import AppButton from "../../components/appButton/appButton";
import LoginIcon from "@mui/icons-material/Login";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import logo from "../../images/midenasLogo.png";
import { useNavigate } from "react-router-dom";
import { routes } from "../../app/router/routes";
import LoginRequest from "../../models/auth/requests/loginRequest";
import { useAppDispatch, useAppSelector } from "../../app/redux/hooks";
import {
  login,
  selectAccount,
  selectLoginStatus,
} from "../../slices/authSlice";
import SnackbarModel from "../../models/snackbarModel";
import { setSnackbar } from "../../slices/snackbarSlice";
import { INIT_LOGIN_REQUEST } from "../../models/initData/initData";

export default function LoginPage() {
  const { classes } = useLoginPageStyles();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const loginStatus = useAppSelector(selectLoginStatus);
  const account = useAppSelector(selectAccount);

  const [loginRequest, setLoginRequest] =
    useState<LoginRequest>(INIT_LOGIN_REQUEST);

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const handleOnChange = (e: any) => {
    const name = e.target.name;
    const value = e.target.value;
    var newLoginRequest = { ...loginRequest };
    if (name === "email") {
      newLoginRequest.email = value;
    } else {
      newLoginRequest.password = value;
    }
    setLoginRequest(newLoginRequest);
  };

  const handleLogin = () => {
    dispatch(login(loginRequest));
  };

  useEffect(() => {
    if (account !== null) {
      navigate(routes.dashboard);
    }
  }, [account]);

  useEffect(() => {
    if (loginStatus === "Rejected") {
      const snackbar: SnackbarModel = {
        message:
          "Login failed. Please check your login credentials and try again.",
        severity: "error",
        show: true,
      };
      dispatch(setSnackbar(snackbar));
    }
  }, [loginStatus]);

  return (
    <Container component="main" maxWidth="xs" sx={{ mt: 5 }}>
      <Grid container spacing={1} direction="column" alignItems="center">
        <img className={classes.img} alt="logo" src={logo} />
      </Grid>
      <>
        <Grid container spacing={1} direction="column" alignItems="center">
          <Typography component="span" variant="h6" className={classes.title}>
            Admin Console
          </Typography>
        </Grid>
        <Grid container spacing={1}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            mt={1}
            className={classes.gridBasicInput}
          >
            <BasicInput
              type="email"
              name="email"
              label="Login"
              height="35px"
              onChange={handleOnChange}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            mt={1}
            className={classes.gridBasicInput}
          >
            <BasicInput
              type={showPassword ? "text" : "password"}
              name="password"
              label="Password"
              endAdornment={
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              }
              height="35px"
              onChange={handleOnChange}
            />
          </Grid>
        </Grid>
      </>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        container
        spacing={1}
        direction="column"
        alignItems="center"
        justifyContent="center"
        mb={3}
        mt={3}
      >
        <FormLabel className={classes.forgotPasswordLabel}>
          Forgot password?
        </FormLabel>
      </Grid>
      {loginStatus === "Pending" && (
        <Grid item md={12} xs={12} sm={12} lg={12}>
          <AppButton
            type="submit"
            color={colors.primary}
            label="Login"
            hover={colors.primary}
            width="100%"
            startIcon={
              <CircularProgress
                size="20px"
                className={classes.circularProgress}
              />
            }
            onClick={() => handleLogin()}
            disabled={true}
          />
        </Grid>
      )}
      {loginStatus !== "Pending" && (
        <Grid item md={12} xs={12} sm={12} lg={12}>
          <AppButton
            type="submit"
            color={colors.primary}
            label="Login"
            hover={colors.primary}
            width="100%"
            startIcon={<LoginIcon />}
            onClick={() => handleLogin()}
          />
        </Grid>
      )}
    </Container>
  );
}

import {
  Autocomplete,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AppButton from "../appButton/appButton";
import { useAddUpdateUserModalStyles } from "./addUpdateUserModalStyles";
import BasicInput from "../basicInput/basicInput";
import { Account } from "../../models/account/account";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/redux/hooks";
import RoleModel from "../../models/account/roleModel";
import { getRoles, saveAccount, selectRoles } from "../../slices/accountSlice";
import { INIT_ACCOUNT } from "../../pages/userManagementPage/userManagement";

interface Props {
  handleClose: any;
  open: any;
  account: Account;
  setAccount: any;
  setSelectedRole: any;
  selectedRole: RoleModel | null;
  savedAccountStatus: string;
}

export default function AddUpdateUserModal({
  handleClose,
  open,
  account,
  setAccount,
  selectedRole,
  setSelectedRole,
  savedAccountStatus,
}: Props) {
  const dispatch = useAppDispatch();

  const { classes } = useAddUpdateUserModalStyles();

  const roles = useAppSelector(selectRoles);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    let { name, value } = e.target;
    setAccount((prevState: Account) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleRoleChange = (
    event: React.ChangeEvent<{}>,
    value: RoleModel | null
  ) => {
    setSelectedRole(value);
    if (value) {
      setAccount((prevState: Account) => ({
        ...prevState,
        roleName: value.name,
      }));
    }
  };

  const handleSave = () => {
    dispatch(saveAccount(account));
  };

  useEffect(() => {
    dispatch(getRoles());
  }, []);

  return (
    <>
      <Dialog
        onClose={() => {
          setAccount(INIT_ACCOUNT);
          handleClose();
        }}
        open={open}
        maxWidth="md"
        fullWidth
        id={"add-update-user-modal"}
      >
        <DialogTitle>
          <Grid item md={12} className={classes.dialogTitleWrapper}>
            <Typography> Add user</Typography>
            <IconButton onClick={handleClose}>
              <CloseIcon id={"add-update-icon-close"} />
            </IconButton>
          </Grid>
        </DialogTitle>
        <DialogContent sx={{ padding: "10px" }}>
          <Grid container spacing={2} sx={{ padding: "10px" }}>
            <Grid item md={4}>
              <BasicInput
                id={"add-update-user-input-first-name"}
                label={"First name"}
                value={account.firstName}
                onChange={handleInputChange}
                name="firstName"
              />
            </Grid>
            <Grid item md={4}>
              <BasicInput
                id={"add-update-user-input-last-name"}
                label={"Last name"}
                value={account.lastName}
                onChange={handleInputChange}
                name="lastName"
              />
            </Grid>
            <Grid item md={4}>
              <BasicInput
                id={"add-update-user-input-email"}
                label={"Email"}
                value={account.email}
                onChange={handleInputChange}
                name="email"
              />
            </Grid>
            <Grid item md={6}>
              <BasicInput
                id={"add-update-user-input-phone"}
                label={"Phone"}
                value={account.phone}
                onChange={handleInputChange}
                name="phone"
              />
            </Grid>
            <Grid item md={6}>
              <Typography variant="body1" className={classes.inputTitle}>
                Select role:
              </Typography>
              <Autocomplete
                id={"add-update-user-autocomplete-role"}
                options={roles ? roles : []}
                getOptionLabel={(option) => option.name}
                value={
                  selectedRole ||
                  roles?.find((role) => role.name === account.roleName)
                }
                onChange={handleRoleChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    autoComplete="off"
                    fullWidth
                    className={classes.autocompleteTextField}
                    size="small"
                  />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <AppButton
            id={"add-update-user-button-close"}
            onClick={() => {
              setAccount(INIT_ACCOUNT);
              handleClose();
            }}
            label="Close"
            color={"rgba(255, 73, 71, 0.8)"}
            hover={"rgba(255, 73, 71, 0.8)"}
          />
          <AppButton
            id={"add-update-user-button-save"}
            label={"Save"}
            color={savedAccountStatus === "Pending" ? "lightgrey" : "#4CAF50"}
            hover={"#4CAF50"}
            onClick={handleSave}
            disabled={savedAccountStatus === "Pending"}
            startIcon={
              savedAccountStatus === "Pending" ? (
                <CircularProgress
                  className={classes.circularProgress}
                  size={14}
                />
              ) : (
                ""
              )
            }
          />
        </DialogActions>
      </Dialog>
    </>
  );
}

import React from "react";
import {
  AppBar,
  Divider,
  Drawer,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import MenuIcon from "@mui/icons-material/Menu";
import { useHeaderStyles } from "./headerStyles";
import { routes } from "../../app/router/routes";
import { useNavigate } from "react-router-dom";
import DomainAddIcon from '@mui/icons-material/DomainAdd';
import { useAppDispatch } from "../../app/redux/hooks";
import { logout } from "../../slices/authSlice";
import { PersonAdd } from "@mui/icons-material";

type Anchor = "left";

interface Props {
  title: string;
}

export default function Header({title}: Props) {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const { classes } = useHeaderStyles();
  const navigate = useNavigate();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      setState({ ...state, [anchor]: open });
    };

  const handleOrganisationManagementPage = () => {
    navigate(routes.organisationManagement);
  };

  const handleDashboardPage = () => {
    navigate(routes.dashboard);
  };

  const handleUserManagementPage = () => {
    navigate(routes.userManagement);
  };

  const handleLogout = (): void => {
    dispatch(logout());
    navigate(routes.login);
  };

  const list = (anchor: Anchor) => (
    <Grid
      className={classes.list}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <ListItem button onClick={handleDashboardPage}>
          <ListItemIcon>
            <DashboardIcon className={classes.sidebarIcon} />
          </ListItemIcon>
          <ListItemText>Dashboard</ListItemText>
        </ListItem>
        <ListItem button onClick={handleOrganisationManagementPage}>
          <ListItemIcon>
            <DomainAddIcon className={classes.sidebarIcon} />
          </ListItemIcon>
          <ListItemText>Organisation Management</ListItemText>
        </ListItem>
        <ListItem button onClick={handleUserManagementPage}>
          <ListItemIcon>
            <PersonAdd className={classes.sidebarIcon} />
          </ListItemIcon>
          <ListItemText>User Management</ListItemText>
        </ListItem>
      </List>

      <Divider />

      <List>
        <ListItem button onClick={handleLogout}>
          <ListItemIcon>
            <ExitToAppIcon className={classes.sidebarIcon} />
          </ListItemIcon>
          <ListItemText>Logout</ListItemText>
        </ListItem>
      </List>
    </Grid>
  );
  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <Grid container>
            <Grid item md={4} xs={3}>
              {(["left"] as Anchor[]).map((anchor) => (
                <React.Fragment key={anchor}>
                  <MenuIcon
                    id="header_menu-icon"
                    style={{ marginTop: "12px" }}
                    onClick={toggleDrawer(anchor, true)}
                    className={classes.menuIcon}
                  />
                  <Drawer
                    anchor={anchor}
                    open={state[anchor]}
                    onClose={toggleDrawer(anchor, false)}
                  >
                    {list(anchor)}
                  </Drawer>
                </React.Fragment>
              ))}
            </Grid>

            {isDesktop && (
              <Grid item md={4} xs={4} display={"flex"} alignItems={"center"}>
                <Typography variant="h6" className={classes.title}>
                  {title}
                </Typography>
              </Grid>
            )}

            {isMobile && (
              <Grid item md={4} xs={5} display={"flex"} alignItems={"center"}>
                <Typography variant="body1" className={classes.title}>
                {title}
                </Typography>
              </Grid>
            )}
          </Grid>
        </Toolbar>
      </AppBar>
    </div>
  );
}

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Account } from "../models/account/account";
import { RootState } from "../app/redux/store";
import { ApiStatus } from "../models/app/apiStatus";
import {
  Accounts,
  getAccountsByAssociation as getAccountsByAssociationApi,
  saveAccount as saveAccountApi,
  getRoles as getRolesApi,
  activateAccount as activateAccountApi,
  setAccountDelete as setAccountDeleteApi,
} from "../app/redux/adminConsoleApi";
import { Client } from "../models/associations/client";
import RoleModel from "../models/account/roleModel";
import DeleteAccountRequest from "../models/account/deleteAccountRequest";

type AccountState = {
  savedAccount: Account | null;
  clients: Client[] | null;
  accounts: Account[] | null;
  roles: RoleModel[] | null;
  associationId: string | null;
  statuses: {
    savedAccount: ApiStatus;
    getAllClients: ApiStatus;
    isActiveStatus: ApiStatus;
    accountDelete: ApiStatus;
  };
};

const initialState: AccountState = {
  savedAccount: null,
  clients: null,
  accounts: [],
  roles: [],
  associationId: null,
  statuses: {
    savedAccount: ApiStatus.Idle,
    getAllClients: ApiStatus.Idle,
    isActiveStatus: ApiStatus.Idle,
    accountDelete: ApiStatus.Idle,
  },
};

export const selectSavedAccount = (state: RootState) =>
  state.account.savedAccount;
export const selectClients = (state: RootState) => state.account.clients;
export const selectStatusClients = (state: RootState) =>
  state.account.statuses.getAllClients;
export const selectAccounts = (state: RootState) => state.account.accounts;
export const selectRoles = (state: RootState) => state.account.roles;
export const selectAssociationId = (state: RootState) =>
  state.account.associationId;
export const selectActiveStatus = (state: RootState) =>
  state.account.statuses.isActiveStatus;
export const selectAccountDeleteStatus = (state: RootState) =>
  state.account.statuses.accountDelete;

export const selectSavedAccountStatus = (state: RootState) =>
  state.account.statuses.savedAccount;

export const saveAccount = createAsyncThunk(
  "Account/Save",
  async (request: Account) => {
    const response = await saveAccountApi(request);
    return response.data;
  }
);
export const getAllClients = createAsyncThunk(
  "Account/Get_All_Clients",
  async () => {
    const response = await Accounts.GetAllClients();
    return response.data;
  }
);

export const getAccountsByAssociation = createAsyncThunk(
  "Account/get-by-id",
  async (id: string) => {
    const response = await getAccountsByAssociationApi(id);
    return response.data;
  }
);

export const setAccountDelete = createAsyncThunk(
  "Account/delete-account",
  async (request: DeleteAccountRequest) => {
    const response = await setAccountDeleteApi(request);
    return response.data;
  }
);

export const activateAccount = createAsyncThunk(
  "Account/activate-account",
  async (id: string) => {
    const response = await activateAccountApi(id);
    return response.data;
  }
);

export const getRoles = createAsyncThunk("Account/get-all-roles", async () => {
  const response = await getRolesApi();
  return response.data;
});

export const setSelectedAssociationId = createAsyncThunk(
  "Account/set_selected_association_id",
  async (associationId: string | null) => {
    return associationId;
  }
);

export const resetAccounts = createAsyncThunk(
  "Account/reset_accounts",
  async () => {
    return null;
  }
);

export const resetSavedAccountStatus = createAsyncThunk(
  "Account/reset_saved_account_status",
  async () => {
    return initialState.statuses.savedAccount;
  }
);

export const resetActiveStatus = createAsyncThunk(
  "Account/reset_active_status",
  async () => {
    return initialState.statuses.isActiveStatus;
  }
);

export const resetDeleteStatus = createAsyncThunk(
  "Account/reset_delete_status",
  async () => {
    return initialState.statuses.accountDelete;
  }
);

const accountSlice = createSlice({
  name: "account",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      // Save Account
      .addCase(saveAccount.pending, (state) => {
        state.statuses.savedAccount = ApiStatus.Pending;
      })
      .addCase(saveAccount.fulfilled, (state, action) => {
        state.savedAccount = action.payload;
        state.statuses.savedAccount = ApiStatus.Fulfilled;
      })
      .addCase(saveAccount.rejected, (state, action) => {
        state.statuses.savedAccount = ApiStatus.Rejected;
      })

      // Get All Clients
      .addCase(getAllClients.pending, (state) => {
        state.statuses.getAllClients = ApiStatus.Pending;
      })
      .addCase(getAllClients.fulfilled, (state, action) => {
        state.clients = action.payload;
        state.statuses.getAllClients = ApiStatus.Fulfilled;
      })
      .addCase(getAllClients.rejected, (state, action) => {
        state.statuses.getAllClients = ApiStatus.Rejected;
      })

      .addCase(getRoles.fulfilled, (state, action) => {
        state.roles = action.payload;
      })

      .addCase(getAccountsByAssociation.fulfilled, (state, action) => {
        state.accounts = action.payload;
      })
      .addCase(resetAccounts.fulfilled, (state, action) => {
        state.accounts = [];
      })
      .addCase(setSelectedAssociationId.fulfilled, (state, action) => {
        state.associationId = action.payload;
      })
      .addCase(activateAccount.fulfilled, (state, action) => {
        state.statuses.isActiveStatus = ApiStatus.Fulfilled;
      })
      .addCase(setAccountDelete.pending, (state, action) => {
        state.statuses.accountDelete = ApiStatus.Pending;
      })
      .addCase(setAccountDelete.fulfilled, (state, action) => {
        state.statuses.accountDelete = ApiStatus.Fulfilled;
      })
      .addCase(resetActiveStatus.fulfilled, (state, action) => {
        state.statuses.isActiveStatus = initialState.statuses.isActiveStatus;
      })
      .addCase(resetDeleteStatus.fulfilled, (state, action) => {
        state.statuses.accountDelete = initialState.statuses.accountDelete;
      })
      .addCase(resetSavedAccountStatus.fulfilled, (state, action) => {
        state.statuses.savedAccount = initialState.statuses.savedAccount;
      });
  },
});

export default accountSlice.reducer;

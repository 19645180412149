import React from "react";
import { Container, CssBaseline } from "@mui/material";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

interface Props {
  children?: React.ReactNode;
}

export default function Layout({ children }: Props) {
  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="xl">
        <div id="row-layout">{children}</div>
      </Container>
      <ToastContainer autoClose={5000} position="bottom-left" theme="colored" />
    </React.Fragment>
  );
}

import LoginRequest from "../auth/requests/loginRequest";
import { ApiStatus } from "../app/apiStatus";

export const INIT_LOGIN_REQUEST: LoginRequest = {
  email: "",
  password: "",
};
export const INIT_API_DATA = {
  status: ApiStatus.Idle,
};

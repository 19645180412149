import { Container, Grid } from "@mui/material";
import Header from "../../features/header/header";
import { OrganisationManagement } from "./organisationManagement";

export default function OrganisationManagementPage() {
  return (
    <Container component="main" maxWidth="xl">
      <Grid>
        <Header title="Organisation Management"/>
      </Grid>
      <Grid mt={10} item xs={12} sm={12}>
        <OrganisationManagement />
      </Grid>
    </Container>
  );
}

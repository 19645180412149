import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../app/redux/store";
import { ApiStatus } from "../models/app/apiStatus";
import { Module } from "../models/associations/module";
import { Modules } from "../app/redux/adminConsoleApi";

type ModuleState = {
  modules: Module[] | null;

  statuses: {
    getAllModules: ApiStatus;
  };
};

const initialState: ModuleState = {
  modules: null,

  statuses: {
    getAllModules: ApiStatus.Idle,
  },
};

export const getAllModules = createAsyncThunk(
  "Modules/Get_All_Modules",
  async () => {
    const response = await Modules.GetAll();
    return response.data;
  }
);

const moduleSlice = createSlice({
  name: "module",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      // Get All Modules
      .addCase(getAllModules.pending, (state) => {
        state.statuses.getAllModules = ApiStatus.Pending;
      })
      .addCase(getAllModules.fulfilled, (state, action) => {
        state.modules = action.payload;
        state.statuses.getAllModules = ApiStatus.Fulfilled;
      })
      .addCase(getAllModules.rejected, (state, action) => {
        state.statuses.getAllModules = ApiStatus.Rejected;
      });
  },
});

export const selectModules = (state: RootState) => state.module.modules;
export const selectStatusModules = (state: RootState) =>
  state.module.statuses.getAllModules;

export default moduleSlice.reducer;

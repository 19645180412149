import { Container, Grid } from "@mui/material";
import Header from "../../features/header/header";
import { UserManagement } from "./userManagement";

export default function UserManagementPage() {
  return (
    <Container component="main" maxWidth="xl">
      <Grid>
        <Header title="User Management"/>
      </Grid>
      <Grid mt={10} item xs={12} sm={12}>
        <UserManagement />
      </Grid>
    </Container>
  );
}

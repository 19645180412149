import Layout from "./app/theme/layout";
import AppRouter from "./app/router/appRouter";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectSnackbar } from "./slices/snackbarSlice";
import { Alert, Snackbar } from "@mui/material";

function App() {
  const snackbar = useSelector(selectSnackbar);

  const [openSnackbar, setOpenSnackbar] = useState(false);

  useEffect(() => {
    if (snackbar.show) {
      setOpenSnackbar(true);
    }
  }, [snackbar]);

  const handleCloseSnackbar = (reason: string) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };
  return (
    <Layout>
      <AppRouter />
      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={(event, reason) => handleCloseSnackbar(reason)}
      >
        <Alert
          onClose={() => handleCloseSnackbar("")}
          severity={snackbar.severity}
          variant="filled"
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Layout>
  );
}

export default App;

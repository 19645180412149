import { useEffect } from "react";
import { Container, Grid } from "@mui/material";
import Header from "../../features/header/header";
import Dashboard from "./dashboard";
import { useNavigate } from "react-router-dom";
import { routes } from "../../app/router/routes";
import { useAppSelector } from "../../app/redux/hooks";
import { selectAccount } from "../../slices/authSlice";

export default function DashboardPage() {
  const navigate = useNavigate();
  const account = useAppSelector(selectAccount);
  
  useEffect(() => {
    if (account === null) {
      navigate(routes.login);
    }
  }, [account]);

  return (
    <Container component="main" maxWidth="xs" sx={{ mt: 5 }}>
      <Grid>
        <Header title="Dashboard Page"/>
      </Grid>
      <Grid mt={10} item xs={12} sm={12}>
        <Dashboard />
      </Grid>
    </Container>
  );
}

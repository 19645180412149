import {
  Autocomplete,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../app/redux/hooks";

import { useUserManagementPageStyles } from "./userManagementPageStyles";
import AssociationShort from "../../models/associations/associationShort";
import { useEffect, useState } from "react";
import {
  getAllAssociations,
  selectAssociations,
} from "../../slices/associationSlice";
import AppButton from "../../components/appButton/appButton";
import { colors } from "../../app/theme/colors";
import AddUpdateUserModal from "../../components/modals/addUpdateUserModal";
import { EmptyGuid } from "../../constants/constants";
import { Account } from "../../models/account/account";
import {
  activateAccount,
  getAccountsByAssociation,
  resetAccounts,
  resetActiveStatus,
  resetDeleteStatus,
  resetSavedAccountStatus,
  selectAccountDeleteStatus,
  selectAccounts,
  selectActiveStatus,
  selectAssociationId,
  selectSavedAccountStatus,
  setAccountDelete,
  setSelectedAssociationId,
} from "../../slices/accountSlice";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import CreateOutlinedIcon from "@mui/icons-material/Create";
import { toast } from "react-toastify";
import RoleModel from "../../models/account/roleModel";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteAccountRequest from "../../models/account/deleteAccountRequest";

export const INIT_ACCOUNT: Account = {
  isMidenas: false,
  email: "",
  firstName: "",
  lastName: "",
  phone: "",
  roleName: "",
  associationId: EmptyGuid,
  id: EmptyGuid,
  isActive: false,
  isDeleted: false,
};
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  whiteSpace: "nowrap",
}));
export const UserManagement = () => {
  const dispatch = useAppDispatch();

  const { classes } = useUserManagementPageStyles();

  const organisations = useAppSelector(selectAssociations);
  const accounts = useAppSelector(selectAccounts);
  const savedAccountStatus = useAppSelector(selectSavedAccountStatus);
  const activeStatus = useAppSelector(selectActiveStatus);
  const deleteStatus = useAppSelector(selectAccountDeleteStatus);

  const [addUpdateUserModalOpen, setAddUpdateUserModalOpen] = useState(false);
  const [account, setAccount] = useState<Account>(INIT_ACCOUNT);

  const INIT_DELETE_ACCOUNT_REQUEST: DeleteAccountRequest = {
    id: EmptyGuid,
    isDeleted: true,
  };

  const [deleteAccount, setDeleteAccount] = useState<DeleteAccountRequest>(
    INIT_DELETE_ACCOUNT_REQUEST
  );

  const [selectedAssociation, setSelectedAssociation] =
    useState<AssociationShort | null>(null);
  const [selectedRole, setSelectedRole] = useState<RoleModel | null>(null);

  const handleAssociationChange = (
    event: React.ChangeEvent<{}>,
    value: AssociationShort | null
  ) => {
    setSelectedAssociation(value);
    if (value) {
      dispatch(setSelectedAssociationId(value.id));
      setAccount((prevState: Account) => ({
        ...prevState,
        associationId: value.id,
      }));
      dispatch(getAccountsByAssociation(value.id));
    } else {
      dispatch(resetAccounts());
    }
  };

  const handleActivateAccount = (accountId: string) => {
    dispatch(activateAccount(accountId));
  };

  const handleDeleteAccount = (accountId: string) => {
    setDeleteAccount((prevState: DeleteAccountRequest) => ({
      ...prevState,
      id: accountId,
    }));
  };

  useEffect(() => {
    dispatch(setAccountDelete(deleteAccount));
  }, [deleteAccount]);

  useEffect(() => {
    if (savedAccountStatus === "Fulfilled") {
      toast.success("Account is created!", {
        position: "bottom-left",
        autoClose: 3000,
        hideProgressBar: true,
        onClose: () => {
          dispatch(resetSavedAccountStatus());
        },
      });
      const associationId = selectedAssociation?.id ?? "";

      dispatch(getAccountsByAssociation(associationId));

      setAccount((prevState: Account) => ({
        ...INIT_ACCOUNT,
        associationId: associationId,
      }));

      setAddUpdateUserModalOpen(false);
      setSelectedRole(null);
    }
  }, [savedAccountStatus, selectedAssociation]);

  useEffect(() => {
    if (deleteStatus === "Fulfilled") {
      toast.success("Account is deleted!", {
        position: "bottom-left",
        autoClose: 3000,
        hideProgressBar: true,
        onClose: () => {
          dispatch(resetDeleteStatus());
        },
      });
      const associationId = selectedAssociation?.id ?? "";

      dispatch(getAccountsByAssociation(associationId));
    }
  }, [deleteStatus]);

  useEffect(() => {
    if (activeStatus === "Fulfilled") {
      const associationId = selectedAssociation?.id ?? "";
      dispatch(getAccountsByAssociation(associationId));
      dispatch(resetActiveStatus());
    }
  }, [activeStatus, selectedAssociation]);

  useEffect(() => {
    dispatch(getAllAssociations());
  }, []);

  return (
    <Grid container>
      <Grid container>
        <Grid item md={6} mb={2}>
          <Typography variant="body1" className={classes.inputTitle}>
            Select organisation
          </Typography>
          <Autocomplete
            id={"user-management-autocomplete-organisations"}
            options={organisations ? organisations : []}
            getOptionLabel={(option) => option.name}
            value={selectedAssociation}
            onChange={handleAssociationChange}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                autoComplete="off"
                fullWidth
                className={classes.autocompleteTextField}
                size="small"
              />
            )}
          />
        </Grid>
      </Grid>
      <Grid>
        <AppButton
          id={"user-management-button-add-user"}
          color={colors.primary}
          hover={colors.primary}
          label={"Add user"}
          onClick={() => setAddUpdateUserModalOpen(true)}
          disabled={!selectedAssociation}
        />
      </Grid>
      <Grid item md={12} mt={2}>
        <TableContainer>
          <Table id={"user-management-table-user"} size="small">
            <TableHead
              style={{
                background: colors.primary,
                color: "white",
                borderBottom: "none",
                border: "none",
              }}
              sx={{
                "& .MuiTableRow-root > .MuiTableCell-root": {
                  color: "white",
                },
              }}
            >
              <StyledTableRow>
                <StyledTableCell>No.</StyledTableCell>
                <StyledTableCell>Actions</StyledTableCell>
                <StyledTableCell>First name</StyledTableCell>
                <StyledTableCell>Last name</StyledTableCell>
                <StyledTableCell>Birth date</StyledTableCell>
                <StyledTableCell>Phone</StyledTableCell>
                <StyledTableCell>Active</StyledTableCell>
                <StyledTableCell>Role</StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {accounts?.map((account, index) => (
                <StyledTableRow>
                  <StyledTableCell>{index + 1}</StyledTableCell>
                  <StyledTableCell>
                    <CreateOutlinedIcon
                      style={{ color: "#00B2FF", cursor: "pointer" }}
                      onClick={() => {
                        setAccount(account);
                        setAddUpdateUserModalOpen(true);
                      }}
                    />
                    <VisibilityIcon
                      style={{
                        color: "#3C7F9C",
                        marginLeft: "10px",
                        marginRight: "10px",
                        cursor: "pointer",
                      }}
                    />
                    <DeleteIcon
                      style={{ color: "#DE2C2C", cursor: "pointer" }}
                      onClick={() => handleDeleteAccount(account.id)}
                    />
                  </StyledTableCell>
                  <StyledTableCell>{account?.firstName}</StyledTableCell>
                  <StyledTableCell>{account?.lastName}</StyledTableCell>
                  <StyledTableCell>{account?.email}</StyledTableCell>
                  <StyledTableCell>{account?.phone}</StyledTableCell>

                  <StyledTableCell>
                    <FiberManualRecordIcon
                      onClick={() => handleActivateAccount(account?.id)}
                      sx={{
                        color: account.isActive ? colors.green : colors.red,
                        cursor: "pointer",
                      }}
                    />
                  </StyledTableCell>
                  <StyledTableCell>{account?.roleName}</StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <AddUpdateUserModal
        handleClose={() => setAddUpdateUserModalOpen(false)}
        open={addUpdateUserModalOpen}
        account={account}
        setAccount={setAccount}
        selectedRole={selectedRole}
        setSelectedRole={setSelectedRole}
        savedAccountStatus={savedAccountStatus}
      />
    </Grid>
  );
};

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../app/redux/store";
import { ApiStatus } from "../models/app/apiStatus";
import { AssociationDto } from "../models/associations/associationDto";
import {
  saveOrganisation as saveOrganisationApi,
  getAssociations as getAssociationsApi,
  getAssociationById as getAssociationByIdApi,
  getRoles as getRolesApi,
  saveAccount as saveAccountApi,
} from "../app/redux/adminConsoleApi";
import AssociationShort from "../models/associations/associationShort";
import RoleModel from "../models/account/roleModel";
import { Account } from "../models/account/account";

type AssociationState = {
  savedAssociation: AssociationDto | null;
  associations: AssociationShort[] | null;
  associationById: AssociationDto | null;
  statuses: {
    saveAssociation: ApiStatus;
  };
};

const initialState: AssociationState = {
  savedAssociation: null,
  associations: [],
  associationById: null,
  statuses: {
    saveAssociation: ApiStatus.Idle,
  },
};

export const selectSavedAssociation = (state: RootState) =>
  state.association.savedAssociation;
export const selectStatusSaveAssociation = (state: RootState) =>
  state.association.statuses.saveAssociation;
export const selectAssociations = (state: RootState) =>
  state.association.associations;
export const selectAssociationById = (state: RootState) =>
  state.association.associationById;

export const saveOrganisation = createAsyncThunk(
  "Association/Save",
  async (request: AssociationDto) => {
    const response = await saveOrganisationApi(request);
    return response.data;
  }
);

export const getAllAssociations = createAsyncThunk(
  "Association/get-all",
  async () => {
    const response = await getAssociationsApi();
    return response.data;
  }
);

export const getAssociationById = createAsyncThunk(
  "Association/get-by-id",
  async (id: string) => {
    const response = await getAssociationByIdApi(id);
    return response.data;
  }
);

export const resetSelectedAssociation = createAsyncThunk(
  "sureVotes/reset_selected_association",
  async () => {
    return null;
  }
);


export const resetSavedAssociationStatus = createAsyncThunk(
  "Account/reset_saved_association_status",
  async () => {
    return initialState.statuses.saveAssociation;
  }
);

const associationSlice = createSlice({
  name: "association",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(saveOrganisation.pending, (state) => {
        state.statuses.saveAssociation = ApiStatus.Pending;
      })
      .addCase(saveOrganisation.fulfilled, (state, action) => {
        state.savedAssociation = action.payload;
        state.statuses.saveAssociation = ApiStatus.Fulfilled;
      })
      .addCase(saveOrganisation.rejected, (state, action) => {
        state.statuses.saveAssociation = ApiStatus.Rejected;
      })
      .addCase(getAllAssociations.fulfilled, (state, action) => {
        state.associations = action.payload;
      })
      .addCase(getAssociationById.fulfilled, (state, action) => {
        state.associationById = action.payload;
      })

      .addCase(resetSelectedAssociation.fulfilled, (state, action) => {
        state.associationById = null;
      })
      .addCase(resetSavedAssociationStatus.fulfilled, (state, action) => {
        state.statuses.saveAssociation = initialState.statuses.saveAssociation;
      });
  },
});

export default associationSlice.reducer;

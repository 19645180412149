import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { colors } from "../../app/theme/colors";

export const useLoginPageStyles = makeStyles()((theme: Theme) => ({
  btn: {
    textTransform: "none",
    backgroundColor: "#fff",
    borderRadius: "5px",
    boxShadow:
      "0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)",
    float: "right",
    color: colors.primary,
    marginBottom: "5px",
    "&:hover": {
      background: colors.primary,
      color: "white",
    },
  },
  btnResetPass: {
    textTransform: "none",
    backgroundColor: "#fff",
    borderRadius: "5px",
    boxShadow:
      "0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)",
    float: "right",
    color: colors.green,
    marginBottom: "5px",
    "&:hover": {
      background: colors.green,
      color: "white",
    },
  },
  gridBasicInput: {
    "> .MuiFormControl-root > .MuiOutlinedInput-root > input": {
      padding: "6px 12px 6px 12px",
    },
  },
  circularProgress: {
    color: colors.primary,
  },
  forgotPasswordLabel: {
    cursor: "pointer",
  },
  img: {
    width: "250px",
  },
  title: {
    color: "gray",
    marginTop: "15px",
  },
}));

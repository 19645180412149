import axios, { AxiosRequestConfig } from "axios";

const Env = {
  Local: "https://localhost:6001/api",
  Prod: "https://admin.api.easymembership.net/api",
};

const apiUrl = Env.Prod;

const api = (method: string, url: string, variables?: any, jwtToken?: string) =>
  new Promise((resolve, reject) => {
    axios({
      baseURL: apiUrl,
      url: url,
      method,
      params: method === "get" ? variables : undefined,
      data: method !== "get" ? variables : undefined,
      headers: {
        Authorization: localStorage.getItem("accessToken")
          ? "Bearer " + localStorage.getItem("accessToken")
          : NaN,
      },
    } as AxiosRequestConfig).then(
      (response) => {
        resolve(response.data);
      },
      (error) => {
        if (error.response) {
          reject(error.response.data.error);
        } else {
          reject({
            code: "INTERNAL_ERROR",
            status: 503,
            data: {},
          });
        }
      }
    );
  });
export default {
  get: (url: string, variables?: any) => api("get", url, variables),
  post: (url: string, variables?: any) => api("post", url, variables),
  put: (url: string, variables?: any) => api("put", url, variables),
  patch: (url: string, variables?: any) => api("patch", url, variables),
  delete: (url: string, variables?: any) => api("delete", url, variables),
};

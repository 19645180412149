import { Account } from "../models/account/account";
import { AssociationDto } from "../models/associations/associationDto";
import DatabaseModel from "../models/database/databaseModel";

export const EmptyGuid = "00000000-0000-0000-0000-000000000000";

export const InitAccount: Account = {
  id: EmptyGuid,
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  isActive: false,
  isDeleted: false,
  roleName: "",
  isMidenas: false,
};

export const InitDatabase: DatabaseModel = {
  associationId: "",
  databaseName: "",
  databaseTypeId: "",
  id: "",
  password: "",
  serverName: "",
  username: "",
  label: ""
};

export const InitAssociationDto: AssociationDto = {
  association: {
    databases: [],
    id: EmptyGuid,
    name: "",
    email: "",
    logo: "",
    moduleIds: [],
    color: {
      associationId: EmptyGuid,
      error: "",
      id: EmptyGuid,
      primary: "",
      secondary: "",
      success: "",
    },
  owner: {
    id: EmptyGuid,
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    roleName: "",
    rolePriority: 0,
    isMidenas: false,
    isActive: false,
    isDeleted: false,
    associationId: EmptyGuid,
  },
  membershipFee: false,
  defaultAmount: 0,
},

};

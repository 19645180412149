import {
  FormControl,
  FormHelperText,
  OutlinedInput,
  Typography,
} from "@mui/material";
import { ChangeEvent } from "react";
import { useBasicInputStyles } from "./basicInputStyles";
interface Props {
  label?: any;
  value?: any;
  onChange?: (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  type?: string;
  error?: boolean;
  errorMessage?: any;
  disabled?: boolean;
  inputComponent?: any;
  name?: string;
  endAdornment?: any;
  id?: string;
  autofocus?: boolean;
  helperText?: any;
  height?: any;
  width?: string;
  readOnly?: boolean;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => any;
  required?: boolean;
}
const BasicInput = ({
  onChange,
  autofocus,
  disabled,
  endAdornment,
  error,
  errorMessage,
  height,
  helperText,
  id,
  inputComponent,
  label,
  name,
  onKeyDown,
  readOnly,
  type,
  value,
  width,
  required,
}: Props) => {
  const { classes } = useBasicInputStyles();
  return (
    <FormControl
      variant="outlined"
      className={
        error === true ? classes.formControlError : classes.formControl
      }
      fullWidth
    >
      <Typography variant="body1" className={classes.inputTitle}>
        {label}
        {error !== undefined && (
          <span className={error ? classes.requiredSymbolError : ""}></span>
        )}
        {required === true && (
          <span className={classes.requiredSymbolError}>*</span>
        )}
        :
      </Typography>{" "}
      <OutlinedInput
        autoFocus={autofocus}
        id={id}
        autoComplete="new-password"
        disabled={disabled}
        fullWidth
        type={type ? type : "text"}
        value={value}
        onChange={onChange}
        className={classes.input}
        style={{
          background: disabled ? "lightgrey" : "white",
          height: height,
          width: width,
        }}
        inputComponent={inputComponent}
        name={name}
        endAdornment={endAdornment}
        readOnly={readOnly}
        onKeyDown={onKeyDown}
      />{" "}
      <FormHelperText>{helperText}</FormHelperText>{" "}
      {error === true && (
        <span className={classes.errorMessage}>{errorMessage}</span>
      )}
    </FormControl>
  );
};
export default BasicInput;

import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
import snackbarReducer from "../../slices/snackbarSlice";
import authReducer from "../../slices/authSlice";
import accountReducer from "../../slices/accountSlice";
import moduleReducer from "../../slices/moduleSlice";
import associationReducer from "../../slices/associationSlice";

export const store = configureStore({
  reducer: {
    snackbar: snackbarReducer,
    auth: authReducer,
    account: accountReducer,
    module: moduleReducer,
    association: associationReducer,
  },
  devTools: true,
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

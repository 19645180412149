import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import DashboardPage from "../../pages/dashboardPage/dashboardPage";
import LoginPage from "../../pages/loginPage/loginPage";
import { routes } from "./routes";
import OrganisationManagementPage from "../../pages/organisationManagementPage/organisationManagementPage";
import UserManagementPage from "../../pages/userManagementPage/userManagementPage";

const AppRouter = () => {
  return (
    <Router>
      <Routes>
        <Route path={routes.login} element={<LoginPage />} />
        <Route path={routes.dashboard} element={<DashboardPage />} />
        <Route path={routes.organisationManagement} element={<OrganisationManagementPage />} />
        <Route path={routes.userManagement} element={<UserManagementPage />} />
      </Routes>
    </Router>
  );
};

export default AppRouter;
import { makeStyles } from "tss-react/mui";
import { Theme } from "@mui/material/styles";
import { colors } from "../../app/theme/colors";

export const useHeaderStyles = makeStyles()((theme: Theme) => ({
  root: {
    flexGrow: 1,
  },
  appBar: {
    width: "100%",
    background: colors.primary,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    textAlign: "center",
  },
  userSettings: {
    flexGrow: 1,
    padding: "5px",
    fontSize: "16px",
    color: "#A2A89A",
    background: "transparent",
    [theme.breakpoints.down("xs")]: {
      minWidth: "100%",
    },
  },
  grow: {
    flexGrow: 1,
  },
  list: {
    width: 350,
  },
  fullList: {
    width: "auto",
  },
  menuIcon: {
    cursor: "pointer",
    color: "white",
  },
  sidebarIcon: {
    color: colors.primary,
  },
  toolbar: {
    background: "#849BA4",
    width: "100%",
  },
  logo: {
    width: "150px",
    marginLeft: "10px",
    marginRight: "10px",
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
  logout_btn: {
    color: colors.primary,
    textTransform: "capitalize",
    "&:hover": {
      background: "transparent",
    },
    [theme.breakpoints.down("xs")]: {
      float: "left",
      marginTop: "10px",
    },
  },
  logout_btn_disabled: {
    color: colors.primary,
    textTransform: "capitalize",
    background: "#cccccc",
    "&:hover": {
      background: "transparent",
    },
    [theme.breakpoints.down("xs")]: {
      float: "left",
      marginTop: "10px",
    },
  },
  iconButton: {
    float: "right",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
      float: "right",
    },
  },
  menuGridIcon: {
    textAlign: "center",
    justifyContent: "center",
    marginBottom: "-10px",
    marginRight: "2px",
  },
  menuGridTypography: {
    textAlign: "center",
    justifyContent: "center",
    marginBottom: "5px",
  },
  menuTypographyName: {
    fontWeight: 500,
    textTransform: "uppercase",
    color: colors.primary,
  },
  menuTypographyRole: {
    textTransform: "uppercase",
    color: "#6D7C87",
  },
  icon: {
    color: colors.primary,
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  divider: {
    background: "#9C9C9C",
  },
  dividerMargin: {
    background: "#9C9C9C",
    marginBottom: "8px",
  },
  menuItem: {
    padding: "6px 12px",
    "&:not(:last-child)": {
      marginBottom: 8,
    },
  },
}));
